import React from 'react';
import { FC } from 'react';
import { Box, Card, CardContent, colors, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Logo from 'src/components/Logo';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    backgroundColor: colors.red[600]
  },
  stepper: {
    backgroundColor: 'transparent'
  }
}));

const HomeView: FC = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Home">
      <Card>
        <CardContent>
          <Box style={{ margin: '0 auto', textAlign: 'center', padding: 100 }}>
            <Logo style={{maxWidth: '100%'}}/>
            <Hero />
          </Box>
        </CardContent>
      </Card>
    </Page>
  );
};

export default HomeView;
