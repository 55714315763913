import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface OnboardGuardProps {
  children?: ReactNode;
}

const OnboardGuard: FC<OnboardGuardProps> = ({ children }) => {
  const { isAuthenticated, profile } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!profile.onboarded) {
    return <Redirect to="/wizard" />;
  }

  return (
    <>
      {children}
    </>
  );
};

OnboardGuard.propTypes = {
  children: PropTypes.node
};

export default OnboardGuard;
