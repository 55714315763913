/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { FC, ReactNode } from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';

import {
  FileText as FileTextIcon,
  Folder as FolderIcon,
  PieChart as PieChartIcon,
  Clipboard,
  Database,
  Zap
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  requireAdmin?: boolean;
}

interface Section {
  items: Item[];
  subheader: string;
}

const sections: Section[] = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/orders'
      },
      {
        title: 'Claims',
        icon: Clipboard,
        href: '/claims'
      },
      {
        title: 'Admin',
        icon: Database,
        href: '/claims/admin',
        requireAdmin: true
      },
      {
        title: 'Charge Merchants',
        icon: Zap,
        href: '/admin/charge',
        requireAdmin: true
      },
      {
        title: 'Analytics',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      },
      {
        title: 'Reports',
        icon: FileTextIcon,
        href: '/admin/reports',
        requireAdmin: true
      }
    ]
  }
];

function renderNavItems(
  {
    items,
    pathname,
    depth = 0
  }: {
    items: Item[];
    pathname: string;
    depth?: number;
  },
  isAdmin?: boolean
) {
  const authedItems = items.filter(item => !item.requireAdmin || isAdmin);

  return (
    <List disablePadding>
      {authedItems.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  button: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  active: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium
  },
  buttonLeaf: {
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    }
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { profile, logout } = useAuth();
  const { isAdmin } = profile;
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          {sections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems(
                {
                  items: section.items,
                  pathname: location.pathname
                },
                isAdmin
              )}
            </List>
          ))}
        </Box>
        <Divider />
        <Box p={2}>
          <List>
            <ListItem
              className={clsx(classes.itemLeaf)}
              disableGutters
              style={{ marginBottom: 20 }}
            >
              <RouterLink
                style={{ paddingLeft: 10, textDecoration: 'none' }}
                to={'/app/account'}
              >
                <Typography variant="body1" color="textPrimary">
                  Account
                </Typography>
              </RouterLink>
            </ListItem>

            <ListItem className={clsx(classes.itemLeaf)} disableGutters>
              <span
                style={{ paddingLeft: 10, cursor: 'pointer' }}
                onClick={handleLogout}
              >
                <Typography variant="body1" color="textPrimary">
                  Logout
                </Typography>
              </span>
            </ListItem>

            {/* <NavItem title={'Settings'} depth={0} href="/app/account">
              Settings
            </NavItem>

            <NavItem title={'Logout'} depth={0}>
              <span onClick={handleLogout}>Logout</span>
            </NavItem> */}
          </List>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
