import React, { useCallback, useEffect, useState } from 'react';
import { FC } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormHelperText,
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import Info from '@material-ui/icons/Info';
import { Theme } from 'src/theme';
import Page from 'src/components/Page';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { createClaim } from 'src/lib/firebase';
import { useSnackbar } from 'notistack';
import { getOrderById } from 'src/lib/firebase';
import * as qs from 'qs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  banner: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bannerChip: {
    marginRight: theme.spacing(2)
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  }
}));

const FileClaimView: FC = () => {
  const classes = useStyles();
  const [completed, setCompleted] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { orderId } = useParams<{ orderId: string }>();

  const { search } = useLocation();
  const { email, orderNumber } = qs.parse(search, { ignoreQueryPrefix: true });

  const [preloadedOrder, setPreloadedOrder] = useState<any>({
    customerEmail: email ?? '',
    appOrderNumber: orderNumber ?? ''
  });

  const preloadOrder = useCallback(async () => {
    const response = await getOrderById(orderId);
    response?.order && setPreloadedOrder(response.order);
  }, [setPreloadedOrder, orderId]);

  useEffect(() => {
    if (orderId && !preloadedOrder.id) {
      preloadOrder();
    }
  }, [preloadedOrder, preloadOrder, orderId]);

  const claimActions = [
    // { name: 'Refund', id: 'refund' },
    { name: 'Replace', id: 'replace' }
  ];

  const claimReasons = [
    { name: 'Lost', id: 'lost' },
    { name: 'Damaged', id: 'damaged' },
    { name: 'Stolen', id: 'stolen' }
  ];

  return (
    <Page className={classes.root} title="File a Claim">
      <Container className={classes.cardContainer} maxWidth="sm">
        <Box mb={8} display="flex" justifyContent="center">
          <RouterLink to="/"></RouterLink>
        </Box>
        <Card>
          <CardContent className={classes.cardContent}>
            {completed && (
              <Box mb={3}>
                <Typography color="textPrimary" gutterBottom variant="h2">
                  Claim Submitted!
                </Typography>

                <Typography variant="body2" color="textSecondary">
                  Thank you for submitting a claim. We will respond shortly via
                  email.
                </Typography>
              </Box>
            )}

            {!completed && (
              <React.Fragment>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  mb={3}
                >
                  <div>
                    <Typography color="textPrimary" gutterBottom variant="h2">
                      File a Claim
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      So you made an online purchase, and you thought everything
                      would arrive as expected. Unfortunately, packages get lost
                      and items get damaged. Good thing you protected your
                      purchase!
                      <br />
                      <br />
                      Use the form below to submit a claim.
                    </Typography>
                  </div>
                </Box>

                <Formik
                  enableReinitialize
                  initialValues={{
                    email: preloadedOrder
                      ? String(preloadedOrder.customerEmail ?? '')
                      : '',
                    firstName: "",
                    lastName: "",
                    appOrderNumber: preloadedOrder
                      ? String(preloadedOrder.appOrderNumber ?? '')
                      : '',
                    claimAction: 'replace',
                    claimReason: 'lost',
                    message: '',
                    submit: null
                  }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .required('Email is required')
                      .email(),
                      firstName: Yup.string()
                      .required('First name is required'),
                      lastName: Yup.string()
                      .required('Last name is required'),
                    appOrderNumber: Yup.string().required(
                      'Shipping Insurance Order number is required'
                    )
                  })}
                  onSubmit={async (
                    values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      const response = await createClaim({
                        customerEmail: values.email,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        appOrderNumber: values.appOrderNumber,
                        claimAction: 'replace', //values.claimAction,
                        claimReason: values.claimReason,
                        message: values.message
                      });

                      if (response.data) {
                        resetForm();
                        setStatus({ success: true });
                        setCompleted(true);
                      } else {
                        setStatus({ success: false });
                        if (response.error) {
                          enqueueSnackbar(response.error, {
                            variant: 'error'
                          });
                        }
                      }

                      setSubmitting(false);
                    } catch (err) {
                      console.error(err);
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Card>
                        <CardContent>
                        <Grid container spacing={2}>
                            <Grid item md={5} sm={5} xs={5}>
                              <TextField
                                error={Boolean(touched.firstName && errors.firstName)}
                                fullWidth
                                helperText={touched.firstName && errors.firstName}
                                label="First name"
                                name="firstName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.firstName}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={5} sm={5} xs={5}>
                              <TextField
                                error={Boolean(touched.lastName && errors.lastName)}
                                fullWidth
                                helperText={touched.lastName && errors.lastName}
                                label="Last name"
                                name="lastName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.lastName}
                                variant="outlined"
                              />
                            </Grid>                            
                          </Grid>

                          <Grid container spacing={3}>
                            <Grid item md={10} sm={10} xs={10}>
                              <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label="Email Address"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.email}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>

                          <Grid container spacing={3}>
                            <Grid item md={10} sm={10} xs={10}>
                              <TextField
                                error={Boolean(
                                  touched.appOrderNumber &&
                                    errors.appOrderNumber
                                )}
                                fullWidth
                                helperText={
                                  touched.appOrderNumber &&
                                  errors.appOrderNumber
                                }
                                label="Shipping Insurance Order Number"
                                name="appOrderNumber"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.appOrderNumber}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={2} sm={2} xs={2}>
                              <Tooltip title="Please note that this is your Shipping Insurance Order Number and NOT your Shopify order number">
                                <Info />
                              </Tooltip>
                            </Grid>
                          </Grid>

                          <Grid container spacing={3}>
                            <Grid item md={10} sm={10} xs={10}>
                              <TextField
                                fullWidth
                                disabled
                                label="Requested Claim Action"
                                name="claimAction"
                                onChange={handleChange}
                                select
                                SelectProps={{ native: true }}
                                value={values.claimAction}
                                variant="outlined"
                              >
                                {claimActions.map(claimAction => (
                                  <option
                                    key={claimAction.id}
                                    value={claimAction.id}
                                  >
                                    {claimAction.name}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>

                          <Grid container spacing={3}>
                            <Grid item md={10} sm={10} xs={10}>
                              <TextField
                                fullWidth
                                label="Reason for Claim"
                                name="claimReason"
                                onChange={handleChange}
                                select
                                SelectProps={{ native: true }}
                                value={values.claimReason}
                                variant="outlined"
                              >
                                {claimReasons.map(claimReason => (
                                  <option
                                    key={claimReason.id}
                                    value={claimReason.id}
                                  >
                                    {claimReason.name}
                                  </option>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>

                          <Grid container spacing={3}>
                            <Grid item md={10} sm={10} xs={10}>
                              <TextField
                                fullWidth
                                label="Additional Info"
                                name="message"
                                onChange={handleChange}
                                multiline={true}
                                rows={6}
                                value={values.message}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>

                          {errors.submit && (
                            <Box mt={3}>
                              <FormHelperText error>
                                {errors.submit}
                              </FormHelperText>
                            </Box>
                          )}
                        </CardContent>
                        <Divider />
                        <Box p={2} display="flex" justifyContent="flex-end">
                          <Button
                            color="secondary"
                            disabled={isSubmitting}
                            type="submit"
                            variant="contained"
                          >
                            Submit Claim
                          </Button>
                        </Box>
                      </Card>
                    </form>
                  )}
                </Formik>
              </React.Fragment>
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default FileClaimView;
