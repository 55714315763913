import React from 'react';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Link, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';

interface TopBarProps {
  className?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    },
    textDecoration: 'none'
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" alignItems="center">
          <div>
            <RouterLink to="/">
              <Logo className={classes.logo} style={{ width: 150 }} />
            </RouterLink>
          </div>

          {/* <Link
            className={classes.link}
            color="textPrimary"
            component={RouterLink}
            to="/"
            underline="none"
            variant="h3"
          >
            ShipInsurer
          </Link> */}
        </Box>
        <Box flexGrow={1} />
        <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/faq"
          underline="none"
          variant="body2"
        >
          Help
        </Link>
        <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/file-claim"
          underline="none"
          variant="body2"
        >
          File a Claim
        </Link>

        <Link
          className={classes.link}
          color="textSecondary"
          component={RouterLink}
          to="/app"
          underline="none"
          variant="body2"
        >
          Dashboard
        </Link>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
