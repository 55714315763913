import firebase from 'firebase/app';
import 'firebase/auth';
import { firebaseConfig } from 'src/config';
import axios from 'axios';
import { Claim, Order } from 'src/types/order';
import moment from 'moment';
import { capitalize } from 'lodash';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const baseUrl = process.env.REACT_APP_API_BASEURL;

let authUser, authIdToken;

firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    authUser = user;
    getIdToken().then(token => (authIdToken = token));
  }
});

export type ApiResponse = {
  status: boolean;
  error?: string;
  message?: string;
  data?: any;
};

/*
 * Utilities
 */

const getIdToken = async () => {
  if (!authUser) {
    return '';
  }

  return authUser.getIdToken();
};

const getAuthHeader = async (idToken?: string) => {
  return { Authorization: `Bearer ${idToken ?? authIdToken}` };
};

const httpGet = async (uri: string, opts?: any) => {
  if (!opts) {
    opts = {};
  }

  opts.headers = await getAuthHeader();

  let response;
  try {
    response = await axios.get(`${baseUrl}${uri}`, opts);
  } catch (e) {
    console.error(e);
    return null;
  }

  if (response.status !== 200) {
    return null;
  }

  return response.data;
};

const httpPut = async (uri: string, data: any, opts?: any) => {
  if (!opts) {
    opts = {};
  }

  if (!opts.header) {
    opts.headers = await getAuthHeader();
  }

  let response;
  try {
    response = await axios.put(`${baseUrl}${uri}`, data, opts);
  } catch (e) {
    console.error(e);
    return null;
  }

  return [200, 201, 204].includes(response?.status) ? response?.data : null;
};

const httpPost = async (uri: string, data: any, opts?: any) => {
  if (!opts) {
    opts = {};
  }

  if (!opts.header) {
    opts.headers = await getAuthHeader();
  }

  let response;
  try {
    response = await axios.post(`${baseUrl}${uri}`, data, opts);
  } catch (e) {
    console.error(e);
    return null;
  }

  if (![200, 201, 204].includes(response?.status)) {
    return null;
  }

  return response.data;
};

/*
 * Profile
 */

export const getProfileByToken = async (idToken: string) => {
  const response = await httpGet('/profile', {
    headers: await getAuthHeader(idToken)
  });

  return response ?? {};
};

export const getProfile = async () => {
  const response = await httpGet('/profile');
  return response ?? {};
};

export const updateProfile = async data => {
  return httpPut('/profile', data);
};

export const updateAuth = async data => {
  return httpPut('/auth', data);
};

export const checkOnboard = async () => {
  return httpGet('/check-onboard');
};

export const getMembershipStats = async () => {
  return httpGet('/membership-stats');
};

export const downloadReport = async (params: any): Promise<any> => {
  const response = { status: true, error: '' };

  const filename = `ShipInsurer-${capitalize(
    params.reportType
  )}s-Report-${moment()
    .utc()
    .format('YYYY-MM-DD')}.csv`;

  return axios({
    url: `${baseUrl}/report`,
    method: 'POST',
    data: params,
    headers: await getAuthHeader(),
    responseType: 'blob'
  })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      return response;
    })
    .catch(err => {
      response.status = false;
      if (err.response?.status === 404) {
        response.error = 'There were no matching records found';
      }
      if (err.response?.status === 400) {
        response.error = 'Invalid report parameters';
      }
      return response;
    });
};

/*
 * Store
 */

export const getCard = async () => {
  return httpGet('/card');
};

export const getStores = async (): Promise<any[]> => {
  const response = await httpGet('/stores');
  if (!response?.records?.length) {
    return [];
  }

  return response.records;
};

export const getStoreThemes = async (): Promise<any[]> => {
  const response = await httpGet('/store/themes');

  if (!response?.length) {
    return [];
  }

  return response;
};

export const updateCard = async (data): Promise<ApiResponse> => {
  const response = { status: true, error: '' };
  const result = await axios.post(`${baseUrl}/card`, data, {
    headers: await getAuthHeader()
  });

  if (result?.data?.error) {
    response.status = false;
    response.error = result.data?.error;
  }

  return response;
};

export const updateShopify = async (data): Promise<ApiResponse> => {
  const response = { status: true, error: '' };
  const result = await httpPost('/shopify', data);

  if (result?.error) {
    response.status = false;
    response.error = result.error;
  }

  return response;
};

export const installWidget = async (themeId: number): Promise<ApiResponse> => {
  const response = { status: true, error: '' };
  const result = await httpPost('/widget-auto-install', { themeId });

  if (result?.error) {
    response.status = false;
    response.error = result.error;
  }

  return response;
};

export const checkWidgetInstall = async (
  themeId: number
): Promise<ApiResponse> => {
  const response = { status: true, error: '', data: [] };
  const result = await httpPost('/widget-auto-install/status', { themeId });
  if (result?.error) {
    response.status = false;
    response.error = result.error;
  }

  response.data = result;

  return response;
};
export const getWidgetTemplates = async () => {
  return await httpGet('/widget-templates');
};

/*
 * Claims
 */

export type ClaimPage = { records: Claim[]; count: number };

export const getClaims = async (
  limit?: number,
  startId?: string,
  endId?: string,
  filters?: any
): Promise<ClaimPage> => {
  const claimPage: ClaimPage = { records: [], count: 0 };
  const data = {
    startId: startId ?? '',
    endId: endId ?? '',
    limit: limit ?? 1000,
    filters: filters ?? {}
  };

  const response = await httpPost('/claims', data);
  if (!response) {
    return claimPage;
  }

  claimPage.records = response.records as Claim[];
  claimPage.count = response.count;

  return claimPage;
};

export const getClaimById = async (claimId): Promise<Claim | null> => {
  const response = await httpGet(`/claims/${claimId}`);
  return response ? (response as Claim) : null;
};

export const updateClaim = async (claimId, data) => {
  return httpPut(`/claims/${claimId}`, data);
};

export const createClaim = async (data): Promise<ApiResponse> => {
  const response = { status: true, error: '', data: null };
  const result = await httpPost('/claim', data);

  if (!result || result?.code) {
    response.status = false;
    response.error = result.data?.message ?? 'There was an internal error';
  }

  if (result.error) {
    response.status = false;
    response.error = result.error;
    return response;
  }

  response.data = result;

  return response;
};

/*
 * Orders
 */

export type OrderPage = { records: Order[]; count: number };

export const getOrders = async (
  limit?: number,
  startId?: string,
  endId?: string,
  filters?: any
): Promise<OrderPage> => {
  const orderPage: OrderPage = { records: [], count: 0 };
  const data = {
    startId: startId ?? '',
    endId: endId ?? '',
    limit: limit ?? 1000,
    filters: filters ?? {}
  };

  const response = await httpPost('/orders', data);
  if (!response) {
    return orderPage;
  }

  orderPage.records = response.records as Order[];
  orderPage.count = response.count;

  return orderPage;
};

export const getOrderById = async (
  orderId,
  refresh?: boolean
): Promise<{ error?: string; order?: Order }> => {
  let uri = `/orders/${orderId}`;
  if (refresh) {
    uri = `${uri}?refresh=1`;
  }
  const response = await httpGet(uri);

  if (!response || response?.error) {
    return { error: response.error || 'Internal server error' };
  }

  return { order: response as Order };
};

/*
 * Meta/Content
 */

export const getStoreStats = async (): Promise<Claim[]> => {
  const response = await httpGet('/store-stats');
  return response ?? {};
};

export const getFaq = async () => {
  return httpGet('/faq');
};

/*
 * Admin
 */
export const getChargeLog = async (): Promise<any[]> => {
  const response = await httpGet('/admin/charges/log');
  if (!response) {
    return [];
  }

  return response;
};

export const getRevShareTable = async (): Promise<any[]> => {
  const response = await httpGet('/admin/revshare/table');
  if (!response) {
    return [];
  }

  return response;
};

export const getRevShareStats = async (): Promise<any[]> => {
  const response = await httpGet('/admin/revshare/stats');
  if (!response) {
    return [];
  }

  return response;
};

export const makeRevSharePayments = async (): Promise<ApiResponse> => {
  const response = { status: true, error: '' };

  const result = await httpGet('/admin/revshare/pay');

  if (result?.data?.error) {
    response.status = false;
    response.error = result.data?.error;
  }

  return response;
};

export const runCharges = async (): Promise<ApiResponse> => {
  const response = { status: true, error: '' };

  const result = await httpGet('/admin/charges/run?execute=1');

  if (result?.data?.error) {
    response.status = false;
    response.error = result.data?.error;
  }

  return response;
};

export default firebase;
