import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Grid,
  Container,
  colors,
  makeStyles,
  Typography,
  Divider
} from '@material-ui/core'
import Page from 'src/components/Page'
import { getFaq } from 'src/lib/firebase'
import clsx from 'clsx'
import reactStringReplace from 'react-string-replace'

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    backgroundColor: colors.red[600]
  },
  stepper: {
    backgroundColor: 'transparent'
  }
}))

const FaqView: FC = () => {
  const classes = useStyles()
  const [faqs, setFaqs] = useState<any[]>([])

  useEffect(() => {
    getFaq().then(response => {
      setFaqs(
        response.map(faq => {
          faq.answer = reactStringReplace(faq.answer, '<br/>', (match, i) => (
            <br key={`br-${i}`} />
          ))
          faq.answer = reactStringReplace(
            faq.answer,
            /<b>([^<]+)<\/b>/,
            (match, i) => <b key={`b-${i}`}>{match}</b>
          )
          faq.answer = reactStringReplace(
            faq.answer,
            /(<a href="[^"]+">[^<]+<\/a>)/,
            (fullMatch, i) => {
              const subMatches = fullMatch.match(
                /(<a href="([^"]+)">([^<]+)<\/a>)/
              )
              return (
                <Link key={`link-${i}`} to={subMatches[2]}>
                  {subMatches[3]}
                </Link>
              )
            }
          )
          return faq
        })
      )
    })
  }, [setFaqs])

  return (
    <Page className={classes.root} title='FAQ'>
      <div className={clsx(classes.root)} style={{ marginTop: 30 }}>
        <Container maxWidth='lg'>
          <Typography variant='h1' color='textPrimary'>
            Frequently asked questions
          </Typography>
          <Box my={3}>
            <Divider />
          </Box>
          <Grid container spacing={3} component='dl'>
            <Grid item xs={12} md={6}>
              <Typography variant='overline' color='secondary'>
                Customer FAQ
              </Typography>

              {faqs.map((faq, index) => (
                <Box mt={6} key={`faq-${index}`}>
                  <dt>
                    <Typography variant='h4' color='textPrimary' gutterBottom>
                      {faq.question}
                    </Typography>
                  </dt>
                  <dd>
                    <Typography variant='body1' color='textSecondary'>
                      {faq.answer}
                    </Typography>
                  </dd>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
    </Page>
  )
}

export default FaqView
