import React from 'react';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Link, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';

interface TopBarProps {
  className?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const classes = useStyles();

  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} width={180} />
        </RouterLink>
        <Box flexGrow={1} />
        <Link
          className={classes.link}
          color="textSecondary"
          onClick={() => handleLogout()}
          underline="none"
          variant="body2"
          style={{ cursor: 'pointer' }}
        >
          Sign Out
        </Link>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
