import React from 'react'
import { FC } from 'react'

interface ShopifyLogoProps {
  [key: string]: any
}

const ShopifyLogo: FC<ShopifyLogoProps> = props => {
  return <img alt='Shopify Logo' src='/static/shopify-logo.png' {...props} />
}

export default ShopifyLogo
